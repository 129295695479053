.MasterContainer {
    height: 95vh;
}

.MasterContainer::after {
    content: "";
    background-image: url("./assets/PlaceholderBackground.jpg");
    background-size: cover;
    opacity: 0.70;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
}