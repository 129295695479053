.AutocompleteInput {
    /* width: 98% !important; */
}

.AssessmentContainer {
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FillParent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.AutocompleteSuggestion {
    position: absolute;
    z-index: 1;
    cursor: 'pointer';
    width: 100%;
    background-color: #fafafa;
}

.TCText {
  font-size: 1em !important;
}

.AutocompleteActiveItem {
    background-color: #ffffff;
    height: 1em;
}

.WhiteBackground {
  background-color: white;
}

.WelcomeCard {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 600px) {
  .WelcomeCard {
    min-width: 90vw;
  }
}

.Hidden {
  position: absolute;
  z-index: -1;
  margin-top: -10vh;
}

.ConfirmButton {
    float: right;
}

.OfferHeader {
  text-align: center;
}

.LoadingText {
  color: white;
  size: 2rem;
}

.OfferBody {
  max-width: 500px;
}

.PrimaryContainer {
    min-height: 60vh;
    min-width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoadingContainer {
  min-height: 60vh;
  min-width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.AutoCompleteParent {
  position: relative;
}

.AppBarTitle {
    text-align: center;
    margin-bottom: auto !important;
    margin-top: auto !important;
}

.AppBar {
    margin-bottom: 2em;
}

.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #89849b;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
    opacity: 0.6;
  }
  
  .pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #2f2f2f;
    position: absolute;
    border-radius: 50%;
  }
  .pulse {
    background: rgba(0,0,0,0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #89849b;
    animation-delay: 1.1s;
  }
  @-moz-keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  @-webkit-keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  @-o-keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  @-moz-keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }
  @-webkit-keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }
  @-o-keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }